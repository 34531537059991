<template>
  <TemplateList :template-type="2"/>
</template>

<script>
import TemplateList from '@/views/template/TemplateList'

export default {
  components: {
    TemplateList,
  },
}
</script>
